import React, {useEffect} from "react"
import { graphql, navigate } from "gatsby"
import styled from 'styled-components';
import logo from "../logga_sv.png"
import { Helmet } from "react-helmet";

/* Affiliate redirect component */
const RedirectPage = ({ data }) => {

  useEffect(() => {
    setTimeout(
      function () {
        navigate(data.link.affiliateLinks.affiliateUrl)
      }, 600)
  });

  return (
    <RedirectLayout>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="content">
        <h1>Tack för att du besökte <span>Svenskaonlinecasinon.se</span></h1>
        <div className="box">
          <img alt="casino" src={logo} width="125" height="125"/>
          <div className="arrows">
            <div className="arrow">
              <div className="a1"></div>
              <div className="a2"></div>
              <div className="a3"></div>
            </div>
          </div>
          <img
            src={data.link.affiliateLinks.image.sourceUrl}
            width="125"
            height="125"
            alt="casino"
          />
        </div>
        <h3>Du skickas nu vidare till <i>{data.link.title}</i></h3>
      </div>
    </RedirectLayout>
  )
};

export default RedirectPage

export const AffiliateLinkQuery = graphql`
  query LinkByID(
    $id: String!
  ) {
    link: wpAffiliateTracker(id: { eq: $id}) {
      uri
      title
      affiliateLinks {
        affiliateUrl
        image {
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`

const RedirectLayout = styled.div`
  height: 100vh;
  width: 100vw;
  background: #152994;
  position: absolute;
  top: 0;
  left: 0;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #fff;

  .box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 0;

    .arrows {
      margin: 0 25px;

      .arrow > div {
        width: 0;
        height: 0;
        border-bottom: 10px solid transparent;
        border-top: 10px solid transparent;
        border-left: 10px solid #f1f2f3;
        border-radius: 2px;
        font-size: 0;
        line-height: 0;
        display: inline-block;
        -webkit-animation: fading 1s infinite linear both;
        animation: fading 1s infinite linear both;
        margin: 0 5px;
    }
    
      .arrow {
        width: 70px;
        text-align: center;
        width: 100%;
        display: flex;
        align-items: center;
    
        .a1 {
          border-bottom: 6px solid transparent;
          border-top: 6px solid transparent;
          border-left: 6px solid #f1f2f3;
        }
    
        .a2 {
          -webkit-animation-delay: 0.25s;
          animation-delay: 0.25s;
          border-bottom: 10px solid transparent;
          border-top: 10px solid transparent;
          border-left: 10px solid #f1f2f3;
        }
    
        .a3 {
          -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
          border-bottom: 14px solid transparent;
          border-top: 14px solid transparent;
          border-left: 14px solid #f1f2f3;
        }
      }
    
      @-webkit-keyframes fading {
        0%, 80%, 100% { 
            opacity: 0.2;
        }
        40% { 
            opacity: 1;
        }
      }
    
      @keyframes fading {
        0%, 80%, 100% {
            opacity: 0.2;
        } 40% {
            opacity: 1;
        }
      }
    }

    img {
      border-radius: 8px;

      @media (max-width: 600px) {
        width: 75px;
        height: 75px;
        max-width: 75px;
      }
    }
  }

  h1, h2, h3 {
    color: #fff;
  }

  h1 {
    font-size: 38px;

    @media (max-width: 600px) {
      font-size: 24px;
    }
  }

  a {
    color: #ffcd00;
  }

  h3 {
    font-size: 16px;
    font-weight: 500;

    i {
      font-style: italic;
      color: var(--color-yellow) !important;
    }
  }

  span {
    color: #ffcd00;
  }

  .hidden {
    display: none;
  }

`;
